<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item" @click="openRegister()">
                <b class="change-login-register-action">Registrarse </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">Inciar sesión</p>
          </div>
          <div class="column is-half login-form-container">
            <b-field
              label="Email"
              class="login-form-field"
              :type="{ 'is-danger': error.email }"
              :message="{ 'El correo no es válido': error.email }"
            >
              <b-input
                placeholder="Ejemplo: nombre@mail.com"
                v-model="loginUser.email"
              ></b-input>
            </b-field>

            <b-field
              label="Contraseña"
              class="login-form-field"
              :type="{ 'is-danger': error.password }"
              :message="{ 'La contraseña es incorrecta': error.password }"
            >
              <b-input
                placeholder="contraseña"
                type="password"
                maxlength="35"
                v-model="loginUser.password"
                password-reveal
              ></b-input>
            </b-field>

            <div class="login-remember-div">
              <b-field class="login-form-field">
                <b-checkbox v-model="rememberMe"
                  >Recordar información</b-checkbox
                >
              </b-field>
              <p class="forgot-password" @click="openPasswordRecovery()">
                ¿Olvidaste tu contraseña?
              </p>
              <p class="forgot-account" @click="openLogoutSpecificUser()">
                ¿Dejaste tu cuenta activa?
              </p>
            </div>
          </div>
          <div class="column is-half login-button">
            <b-button class="login-button" @click="login()"
              >Iniciar sesión</b-button
            >
          </div>
          <div class="divider-container">
            <hr class="solid-divider" />
          </div>
          <div class="change-login-register-container">
            <p class="change-login-register-action" @click="openRegister()">
              ¿Aun no tienes una cuenta? No te preocupes registrarse es fácil y
              rápido!
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs utilities-list">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Almacenes</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Control de artículos</a></li>
            <li><a>Compras</a></li>
            <li><a>Control de clientes</a></li>
            <li><a>Venta</a></li>
            <li><a>Bancos</a></li>
            <li><a>Tesorería</a></li>
            <li><a>Calendario</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import SecureLS from "secure-ls";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
// @ is an alias to /src
export default {
  name: "Login",
  components: {},
  data() {
    return {
      loginUser: {
        email: "",
        password: "",
      },
      error: {
        email: false,
        password: false,
      },
      rememberMe: false,
    };
  },
  mounted() {
    const ls = new SecureLS({ isCompression: false });
    let currentUserRememberMe = ls.get("ERPFCBUserRememberMe");
    if (currentUserRememberMe) {
      this.loginUser.email = JSON.parse(currentUserRememberMe).email;
      this.loginUser.companyMainName = JSON.parse(
        currentUserRememberMe
      ).companyMainName;
    }
  },
  methods: {
    openRegister() {
      this.$router.push({ path: "/Register" });
    },
    async login() {
      const ls = new SecureLS({ isCompression: false });
      this.error = validateFields(this.loginUser, this.error);
      if (checkValidationErrors(this.error)) {
        const response = await this.$store.dispatch("LOGIN", this.loginUser);
        if (response === "Success") {
          if (this.rememberMe) {
            let remberMeUser = {
              email: this.loginUser.email,
            };
            if (ls.get("ERPFCBUserRememberMe"))
              ls.remove("ERPFCBUserRememberMe");
            ls.set("ERPFCBUserRememberMe", JSON.stringify(remberMeUser));
          }
          await this.$store.dispatch("GETCOMPANYINFORMATION");
          this.$router.push("/Dashboards/Initial").catch(() => {});
          let allPromises = [];

          allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
          allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
          allPromises.push(this.$store.dispatch("GETARTICLES"));
          allPromises.push(this.$store.dispatch("GETPROVIDERS"));
          allPromises.push(
            this.$store.dispatch("GETPOSNOSALES", {
              warehouses: [],
            })
          );
          allPromises.push(this.$store.dispatch("GETCLIENTS"));
          allPromises.push(this.$store.dispatch("GETUSERS"));
          allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
          allPromises.push(this.$store.dispatch("GETLASTEXPENSENUMBER"));
          allPromises.push(
            this.$store.dispatch("GETLASTWAREHOUSETRANSFERNUMBER")
          );
          allPromises.push(this.$store.dispatch("GETCOMPANYEMAILS"));
          allPromises.push(this.$store.dispatch("GETLASTPAYMENTNUMBER"));
          allPromises.push(this.$store.dispatch("GETLASTSALENUMBER"));
          allPromises.push(this.$store.dispatch("GETUSERNOTIFICATIONS"));
          allPromises.push(this.$store.dispatch("GETCATEGORIES"));

          Promise.all(allPromises);
        } else if (response === "Wrong Password") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Contraseña incorrecta",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else if (response === "Error user not found") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "El usuario no existe en nuestro sistema",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else if (response === "Error company not found") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "El nombre de la compaía es incorrecto",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else if (response === "User already logged in") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Otra sesión se encuentra activa, solo se puede tener un dispositivo por sesión activa",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else if (response === "User disabled") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Usuario desactivado",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al iniciar sesión",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openPasswordRecovery() {
      this.$router.push({ path: "/PasswordRecovery" });
    },
    openLogoutSpecificUser() {
      this.$router.push({ path: "/LogoutSpecificUser" });
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
